import React, { FC } from 'react';
import { Switch, Route } from 'react-router-dom';

import { routes } from './routes';
import ExploreRafflesScreen from '../pages/ExploreRafflesScreen';
import RaffleDetailsScreen from './raffleDetails';

import AdminHomeScreen from '../pages/admin/AdminHomeScreen';
import AdminRaffleScreen from '../pages/admin/AdminRaffleScreen';


export const Router: FC = () => (
  <Switch>
    <Route path="/" exact component={ExploreRafflesScreen} />
    <Route path={`/:id`} component={RaffleDetailsScreen} />
  </Switch>
);
