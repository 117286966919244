import { RaffleMetaData } from '../lib/types';
import { TESTING } from './misc';
//change these to add or remove raffles (image uri can be url or local, in public folder)
const testWhitelist = new Map<string, RaffleMetaData>([
  [
    'BSK8qVGUykZnMNemoA2myZUt1fzu8i2CtE6U999XU4S9',
    {
      name: 'Primate #4875',
      overviewImageUri: 'https://media.discordapp.net/attachments/972188273378226246/995476354814459964/4874.png?width=577&height=577'
    }
  ],
  [
    '48fczJNx4eBZuNqRVZUJhfvjz7fkvx2Vtxzts3J4a568',
    {
      name: '1 Gothic Degen NFT',
      overviewImageUri: 'https://media.discordapp.net/attachments/972188273378226246/995111440514035832/1473.png?width=577&height=577'
    }
  ],
  [
    'HGxmLABVcJWfRTw9bqbcaFRNe2q3W2K2mo5LQoFVym51',
    {
      name: 'Degen Town #1811',
      overviewImageUri: 'https://cdn.discordapp.com/attachments/972188273378226246/994750205205417984/1811.png'
    }
  ],
  [
    '8cgWc5XMXux9oytGceCW9f3oXFyCfQAd3sQfPMWzcPc',
    {
      name: 'Rank 48 Gothic Ghost #163',
      overviewImageUri: 'https://media.discordapp.net/attachments/972612148436013116/993621181129310238/auction48.png?width=540&height=540'
    }
  ],
  [
    '35X6YDFnXLs2wmuGWXzVAKH1PXkwxkLi34Btp3EVDwAG',
    {
      name: 'Team Akeo #2729',
      overviewImageUri: 'https://media.discordapp.net/attachments/972188273378226246/989147611963490314/jvZ7GN1v3k9UX3KydYaWIlaEsWOvkXilkHqb6mbZx38.png?width=545&height=545'
    }
  ],
  [
    '5KnbF6hTDPLQseCWTK7Dp3pD4ZLyGYLQJntg68xKE6ca',
    {
      name: 'Primate #1921',
      overviewImageUri: 'https://media.discordapp.net/attachments/972188273378226246/987517997054820412/1920.png?width=566&height=566'
    }
  ],
  [
    '9UpkTJGMkVJFFZz6sbgKK32tV6djA7LhxJXxmjSouAf5',
    {
      name: 'Solana Money Boy #3372',
      overviewImageUri: 'https://media.discordapp.net/attachments/972612148436013116/987143056774013059/lYWZ_ByUqXVeOgjcdtP9WsS6Dw7lndljR4ZHZQfuASo.png?width=566&height=566'
    }
  ],
  [
    'EjJjigVuy5tn7YtioDGs3ZkJpGk1iNLzXCtHVQGcyQFH',
    {
      name: '2 SOLMONKEEZ NFT',
      overviewImageUri: 'https://media.discordapp.net/attachments/972612148436013116/985966266814709840/YRc-VPHDZFePY_S77vM5HBIZd9ZtwqlTNbroRbIL-Xg.png?width=489&height=489',
    },
  ],
  [
    '4kVzr7PShRryZ55wD2Wu4FcX6DAfiaZ5mdST5U4hMwmV',
    {
      name: '3 Bubblegoose WL',
      overviewImageUri: 'https://media.discordapp.net/attachments/972612148436013116/982454135779635240/WL-BUBBLEGOOSE.png?width=683&height=683',
    },
    
  ],
  [
    '56d7AadxHuMfW3WNmriAeXnhwyTvEmpdVVKvVRsyq5oA',
    {
      name: '1 Bubblegoose WL',
      overviewImageUri: 'https://media.discordapp.net/attachments/972612148436013116/982454135779635240/WL-BUBBLEGOOSE.png?width=683&height=683',
    },
    
  ],
  [
    'Brz4LEYX4BzZ1Lr4bfLjRUPBYqHapXvtjFRt51RTCPyh',
    {
      name: 'FFF #5862',
      overviewImageUri: 'https://media.discordapp.net/attachments/972612148436013116/983867508190773258/QmQ7Hbgfm2j2KUKALfmxqDqShLP3t7udKAkhtGLp8vmEGi.jpg',
    },
  ]
]);

const prodWhitelist = new Map<string, RaffleMetaData>([
  [
    '48fczJNx4eBZuNqRVZUJhfvjz7fkvx2Vtxzts3J4a568',
    {
      name: '1 Gothic Degen NFT',
      overviewImageUri: 'https://media.discordapp.net/attachments/972188273378226246/995111440514035832/1473.png?width=577&height=577'
    }
  ]
]);

export const RAFFLES_WHITELIST = TESTING
  ? testWhitelist
  : prodWhitelist;
