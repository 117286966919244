import React, { FC } from 'react';
import { useWallet } from '@solana/wallet-adapter-react';
import { WalletMultiButton } from '@solana/wallet-adapter-material-ui';

import styled from 'styled-components';

const WalletButtonContainer = styled(WalletMultiButton)`
  background: transparent;
  border: 1px solid #9B51FF;
  color: #9B51ff;

  transition: background 100ms;
  
  &:hover {
    background: #9B51FF;
    color: #fff;
  }
`;

const WalletButton: FC = () => {
  const { connected } = useWallet();

  return (
    <WalletButtonContainer/>
  );
};

export default WalletButton;
