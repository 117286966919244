import React, { FC, useState } from 'react';
import { AppBar, IconButton } from '@material-ui/core';
import { useWallet } from '@solana/wallet-adapter-react';
import MenuIcon from '@material-ui/icons/Menu';
import { ChevronLeft, Home } from '@material-ui/icons';
import { useHistory } from 'react-router-dom';

import { useStyles } from './styles';
import { routes } from '../../../router/routes';
import { useViewport } from '../../../hooks/useViewport';
import { DeviceType } from '../../../providers/ViewportProvider';
import Drawer from '../Drawer';
import WalletButton from '../WalletButton';
import NavButton from '../NavButton';
import { TESTING } from '../../../config/misc';
import { isAdmin } from '../../AdminRoute';

import styled from "styled-components";

export interface HeaderProps {
  onBackNavigation?: () => void;
}

const LogoSection = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;

  img {
    width: 40px;
    height: 40px;

    margin-left: 1.5rem;
    margin-right: 1.5rem;
  }

  span {
    font-family: Akira;
    font-size: 1.5rem;
  }
`;

const Header: FC<HeaderProps> = ({ onBackNavigation }) => {
  const { device } = useViewport();
  const classes = useStyles({ device });
  const { connected, publicKey } = useWallet();
  const { push, location } = useHistory();
  const [isDrawerOpen, setIsDrawerOpen] = useState(false);

  return (
    <div className={classes.root}>
      <div
        style={{
          width: '100%',
          height: device === DeviceType.Phone ? '50px' : '90px',
          background:
            'linear-gradient(0deg, rgba(0, 0, 0, 0), rgba(0, 0, 0, 0.2))',
          backdropFilter: 'blur(3px)',
          zIndex: 98,
        }}
      />
      <AppBar className={classes.appBar} elevation={0}>
        {device === DeviceType.Phone ? (
          <div className={classes.drawerHeader}>
            {onBackNavigation ? (
              <IconButton size={'medium'} onClick={() => onBackNavigation()}>
                <ChevronLeft />
              </IconButton>
            ) : (<></>)}

          </div>
        ) : (
          <>
            <LogoSection>
              <img
                src="/gkd-logo.png"
                alt={'Site banner'}
                className={classes.homeButtonIcon}
              />
              <span>
                Ghost Kid
              </span>
            </LogoSection>
            <div className={classes.navContainer}>
              <div className={classes.walletButtonContainer}>
                <WalletButton />
              </div>
            </div>
          </>
        )}
      </AppBar>
    </div>
  );
};

export default Header;
