const adminNs = '/admin_panel';

const adminRoutes = {
  HOME: `${adminNs}/`,
};

const routes = {
  HOME: '/',
  STAKE: '/stake',
  TOOLS: '/tools',
  ADMIN: adminRoutes,
};

export { routes };
