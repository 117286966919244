import { Box, Container } from '@material-ui/core';
import React, { FC } from 'react';
import styled from 'styled-components';
import { useViewport } from '../../../hooks/useViewport';
import { DeviceType } from '../../../providers/ViewportProvider';

import { useStyles } from './styles';

export interface BodyProps {}

const Wagboo = styled.div`
  position: relative;
  
  margin-top: 5rem;

  font-size: 50px;

  font-family: BlackRose;
  text-transform: uppercase;
  
  color: white;

  transform: rotateZ(-20deg);

  &.desktop {
    position: absolute;
    bottom: 100px;
    right: 100px;
  }

  span {
    color: #9b51ff;
  }
}
`;

export const Body: FC<BodyProps> = ({ children }) => {
  const { device } = useViewport();
  const classes = useStyles({ device });
  return (
    <Container
      style={{
        width: '90%',
        padding: '0 10px',
        display: ' flex',
        flexDirection: 'column',
        alignItems: 'center',
      }}
    >
      <Box className={classes.root}>{children}</Box>
      <Wagboo className={device == DeviceType.Phone ? "":"desktop"}><span>#</span>Wagboo</Wagboo>
    </Container>
  );
};

export default Body;
